html {
  height: 100%;
  margin: 0;
}

body {
  /*background-color: #002133 !important;*/
  background-image: linear-gradient(#00111a, #076cab) !important;
  background-repeat: no-repeat;
  background-attachment: fixed;
  color: white !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  min-height: 100%;
  position: absolute;
  width: 100%;
  padding-bottom: 15rem;
}

p {
  font-size: 120% !important;
}

p.ref {
  font-size:100%;
  font-style: italic;
}

li {
  font-size: 120%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

footer {
  background-color: #121212 /*#002133*/ /*#282c34*/;
  color: white;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 10rem;
}

a:link, a:visited {
  color: lightskyblue ;
  text-decoration: none;
}

a:hover, a:active {
  color: lightskyblue !important;
  text-decoration: underline !important;
}

table {
  font-size: 120%;
  color: white !important;
  width: 100%;
  border-collapse: collapse;
}

.table {
  --bs-table-striped-color: white !important;
  --bs-table-striped-bg: transparent !important;
  --bs-table-active-color: lightskyblue !important;
  --bs-table-active-bg: transparent !important;
  --bs-table-hover-color: lightskyblue !important;
  --bs-table-hover-bg: transparent !important;
}

tr:hover {
  color: lightskyblue !important;
}

a.nav-link:hover {
  color: lightskyblue !important;
  text-decoration: none !important;
}

a.navbar-brand:hover {
  color: lightskyblue !important;
  text-decoration: none !important;
}

.navbar {
  background-color: #121212 !important;
  width: 100% !important;
}

.accordion-button {
  background-color: #121212 !important;
  color: white !important;
  border-color: #121212 !important;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2387cefa'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}

.accordion-body {
  /*background-color: #035588 !important;*/
  background-image: linear-gradient(#023b5a, #096197) !important;
}

